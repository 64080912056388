import { assign, extend, map } from 'lodash';

// Bloodhound
import('corejs-typeahead');

/* eslint-disable */

window.datepicker_formatDate = function (iso_date) {
  // en fallback if unknow locale
  const datepickerLocale =
    $.datepicker.regional[I18n.locale] || $.datepicker.regional['en'];
  const format = datepickerLocale.dateFormat;

  if (typeof iso_date == 'string') iso_date = new Date(iso_date);

  return $.datepicker.formatDate(format, iso_date);
};

window.extend_with_csrf_params = function (object) {
  const key = $('meta[name=csrf-param]').attr('content');
  object[key] = $('meta[name=csrf-token]').attr('content');

  return object;
};

window.dynamic_input_splitter_into_array = function (userInput) {
  const $userInput = $(userInput);
  $userInput.attr('name', $userInput.attr('name') + ':listField');
};

window.listFieldSerializer = {
  customTypes: {
    listField: function (str) {
      return str
        .split(/,|\n|;/)
        .filter((str) => str)
        .map((str) => str.trim());
    },
  },
};

/**
 * [remote_select2_search description]
 * @param  {Object} input   jQuery DOM Object
 * @param  {Object} options select2 options
 * @return {Object}         Dom Object
 */
window.remote_select2_search = function (input, options) {
  if (typeof options === 'undefined') {
    options = {};
  }

  const $dialog = input.closest('dialog');

  return input.select2(
    assign($dialog.length ? { dropdownParent: $dialog } : {}, {
      width: options.width,
      multiple: options.multiple,
      templateResult: options.templateResult,
      placeholder: options.placeholder,
      minimumInputLength: options.minimumInputLength ?? 1,
      ajax: {
        url: options.url,
        delay: 250,
        dataType: 'JSON',
        data: function (term) {
          return extend(term, {
            network_id: options.network_id,
            topic_id: options.topic_id,
            ...options.query,
          });
        },
        processResults: function (data) {
          const results = { more: false, results: [] };

          $.each(data, function (i, val) {
            results.results.push({
              id: val.id,
              text: val.name,
              name: val.name,
              logo_url: val.logo_url,
              short_description: val.short_description,
              inline_location: val.inline_location,
            });
          });
          results.results = results.results.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
          return results;
        },
      },
    }),
  );
};

window.select2_companies = function (input_companies, options) {
  options.url = Routes.search_companies_path;
  options.templateResult = function (state) {
    return $(
      require('legacy/templates/shared/company_autocomplete.hamlc')(state),
    );
  };

  return remote_select2_search(input_companies, options);
};

window.select2_schools = function (input_schools, options) {
  options.url = Routes.search_schools_path;

  return remote_select2_search(input_schools, options);
};

window.select2_degrees = function (input_degrees, options) {
  options.url = Routes.search_degrees_path;

  return remote_select2_search(input_degrees, options);
};

window.select2_field_of_studies = function (input_field_of_studies, options) {
  options.url = Routes.search_field_of_studies_path;

  return remote_select2_search(input_field_of_studies, options);
};

window.select2_industries = function (input_industries, options) {
  if (typeof options == 'undefined') options = {};

  if (
    AlumniConnect &&
    AlumniConnect.other_infos &&
    AlumniConnect.other_infos.industries
  ) {
    const sortedIndustries = AlumniConnect.other_infos.industries.sort(
      function (a, b) {
        return a.name.localeCompare(b.name);
      },
    );

    const $dialog = input_industries.closest('dialog');

    return input_industries.select2(
      assign($dialog.length ? { dropdownParent: $dialog } : {}, {
        width: options.width,
        multiple: options.multiple,
        templateResult: options.templateResult,
        data: map(sortedIndustries, function (industry) {
          return {
            id: industry.id,
            text: industry.name,
          };
        }),
      }),
    );
  } else {
    options.url = '/search/industries';

    return remote_select2_search(input_industries, options);
  }
};

window.select2_skills = function (input_skills, options) {
  options.url = '/search/skills';

  return remote_select2_search(input_skills, options);
};

// ----------- User Autocomplete --------------------

window.autocomplete_user_typehead = function ($input, options) {
  let engine,
    url,
    userAutocompleteTemplate = require('legacy/templates/frontoffice/shared/item_filters/user_autocomplete.hamlc');

  let params = extend(
    { network_id: options.network_id, format: 'js' },
    options.query,
  );
  options.source = Routes.quick_search_backoffice_networks_path(params);
  engine = new Bloodhound({
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: unescape(
        Routes.quick_search_backoffice_networks_path(
          extend(
            {
              term: '%QUERY',
              format: 'js',
              network_id: options.network_id,
            },
            options.query,
          ),
        ),
      ),
      wildcard: '%QUERY',
    },
  });

  engine.initialize();

  $input.typeahead(
    {
      highlight: true,
      minLength: 1,
      autoselect: true,
      classNames: {
        menu: 'dropdown-menu hard user-search-dropdown',
        cursor: 'search-view--selected',
      },
    },
    {
      display: 'name',
      templates: {
        suggestion: userAutocompleteTemplate,
      },
      source: engine.ttAdapter(),
    },
  );

  $input.on('typeahead:selected', function (event, user) {
    return $($(this).data('el')).val(user.id);
  });
};

window.juridiction_fields_visibility = function (juridiction) {
  const $juridiction = $(juridiction);

  const showJuridictionSpecificFields = function () {
    $('.js-juridiction-specific-field').hide();
    $('.js-juridiction-specific-field-' + $juridiction.val()).show();
  };

  showJuridictionSpecificFields();

  $juridiction.change(function () {
    showJuridictionSpecificFields();
  });
};
