module.exports =(function() {
  return function(context) {
    return (function() {
      var $c, $e, $o;
      $e = function(text, escape) {
        return ("" + text).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&#39;').replace(/\//g, '&#47;').replace(/"/g, '&quot;');
      };
      $c = function(text) {
        switch (text) {
          case null:
          case void 0:
            return '';
          case true:
          case false:
            return '' + text;
          default:
            return text;
        }
      };
      $o = [];
      $o.push("<div class='hard--sides search-item soft-quarter--ends'>\n  <div class='grid grid--full soft-half--left'>\n    <div class='inline-block text--center v-middle' style='width: 32px;'>");
      if (this.logo_url != null) {
        $o.push("      <img src='" + ($e($c(this.logo_url))) + "' height='" + ($e($c(32))) + "' width='" + ($e($c(32))) + "'>");
      } else {
        $o.push("      <i class='icon-building icon-size--200'></i>");
      }
      $o.push("    </div>\n    <div class='color-gray grid__item soft-half--left v-middle'>\n      <div class='weight--bold'>" + ($c()));
      if (this.id) {
        $o.push("        " + $c(this.name));
      } else {
        $o.push("        " + $c(I18n.t('web.companies.add_new_company') + ": " + this.name));
      }
      $o.push("      </div>");
      if ((this.short_description != null) && this.short_description !== '') {
        $o.push("      <div>" + ($c(this.short_description)) + "</div>");
      }
      if (this.inline_location) {
        $o.push("      <div class='f-size--small'>" + ($c(this.inline_location)) + "</div>");
      }
      $o.push("    </div>\n  </div>\n</div>");
      return $o.join("\n").replace(/\s([\w-]+)='true'/mg, ' $1').replace(/\s([\w-]+)='false'/mg, '').replace(/\s(?:id|class)=(['"])(\1)/mg, "").replace(/[\s\n]*\u0091/mg, '').replace(/\u0092[\s\n]*/mg, '');
    }).call(context);
  };

}).call(this);
