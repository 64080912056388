require('corejs-typeahead') # Bloodhound
import { extend, keys, isArray, map, flatten, uniq, isObject } from 'lodash'

# ########################################
# USER CUSTOMIZABLE ATTRIBUTE FORM HELPERS
# ########################################

window.autocomplete_companies =  (container, opts = {}) ->

  companyAutocompleteTemplate = require('legacy/templates/shared/company_autocomplete.hamlc')
  companyAutocompleteCreateTempalte = require('legacy/templates/shared/company_autocomplete_create.hamlc')

  $container = $(container)
  if $container.length == 0
    console.warn('initializeCompanyAutocomplete: no container element provided')

  $container.each  ->
    $container = $(@)
    selected_value = null;

    # INIT

    if opts.input
      $input = $(opts.input)
    else
      $input = $container.find('[data-behavior=search-company-input]')

    if $input.length == 0
      console.warn "initializeCompanyAutocomplete: no input option provied OR no input with attribute '[data-behavior=search-company-input]' found in provided '#{container}' container element"
      return

    if !opts.selectedCallback
      $elem_id_val = $container.find($input.data('el'))
      if $elem_id_val.length == 0
        console.warn "initializeCompanyAutocomplete: No selectedCallback option provied OR can't find '#{$(@).data('el')}' company id input element"
        return


    # INTERN CALLBACKS

    selectedCallback = (event, company) =>
      selected_value = company
      if opts.selectedCallback
        opts.selectedCallback(event, company)
      else
        $elem_id_val.val(company.id)

    closeCallback = (event, value) =>
      # Clear remote cache for when a company is created
      engine.clearRemoteCache();

    blurCallback = (event) =>
      if selected_value
        $input.val(selected_value.name)
      else
        $input.val('')

    # BOOTSTRAP TYPEHEAD

    engine = new Bloodhound({
      queryTokenizer: Bloodhound.tokenizers.whitespace
      datumTokenizer: Bloodhound.tokenizers.whitespace
      remote        : {
        url: unescape(Routes.search_companies_path(term: '%QUERY', network_id: opts.network_id, format: 'js', from_admin: true))
        wildcard: '%QUERY'
      }
    })
    engine.initialize()
    $input.typeahead({
      highlight : true
      minLength : 1
      autoselect: true
      classNames: {
        menu   : 'dropdown-menu hard company-search-dropdown one-whole'
        cursor : 'search-view--selected'
      }
    },{
      display  : 'name'
      limit     : 200
      templates: {
        suggestion: companyAutocompleteTemplate
        footer    : opts.enableCreateCompany && companyAutocompleteCreateTempalte(createCompanyUrl: opts.createCompanyUrl)
        notFound  : opts.enableCreateCompany && companyAutocompleteCreateTempalte(createCompanyUrl: opts.createCompanyUrl)
      }
      source: engine.ttAdapter()
    })


    # LISTENERS

    $input.on('typeahead:selected', selectedCallback)
    $input.on('typeahead:close', closeCallback)
    $input.on('blur', blurCallback)

    if opts.createCompanyCallback
        $container.on('click', '.js-create-company', opts.createCompanyCallback)

window.customizable_attribute_double_select = (elem, opts = {}) ->

  # NAME attribute ####

  base_name_attr = () ->
    "customizable_attribute[json_options]"

  construct_full_name_attr = (option = '') ->
    base_name_attr() + "[options][#{option}][]"

  # DOUBLE INPUTS #####

  double_select_inputs_template = (option) ->
    $("
      <div class='double-select-inputs'>
        <div class='input flush--bottom'>
          <label>#{ I18n.t('views.backoffice.customizable_attributes.double_select.option_label') }</label>
          <input type='text' class='option' />
        </div>
        <div class='input'>
          <label>#{ I18n.t('views.backoffice.customizable_attributes.double_select.child_options_label') }</label>
          <select name='#{construct_full_name_attr()}' multiple='multiple'></select>
        </div>
      </div>
    ")

  delete_button_template = () ->
    $("
      <div class='float--clear push-half--bottom'>
        <a class='button button--delete float--right'>#{I18n.t('views.backoffice.delete')}</a>
      </div>
    ")

  insert_double_select_inputs = (option = '', child_options = null) ->
    $inputs = double_select_inputs_template(option)
    $container.append($inputs)
    $dialog = $inputs.find('select').closest('dialog')
    $select = $inputs.find('select').select2(
      dropdownParent: $dialog if $dialog.length
      tags: true
      tokenSeparators: [';']
      data: child_options
      formatNoMatches: () ->
        'Please enter options'
    )
    $select.val(child_options).trigger('change')
    $input = $inputs.find('input.option').change((e) ->
      $select.attr('name', construct_full_name_attr(e.target.value))
    )
    $input.val(option).trigger('change')

    $delete_button = $inputs.append(delete_button_template()).find('a')
    $delete_button.click( (e) ->
      $inputs.remove()
    )

  bootstrap_double_select_inputs_data = () ->
    for option, child_options of data.options
      insert_double_select_inputs(option, child_options)


  # CHILD INPUT LABEL ##

  child_label_input_template = (val = '') ->
    $("
      <div>
        <label>#{ I18n.t('views.backoffice.customizable_attributes.double_select.child_label_input_label') }</label>
        <input type='text' name='#{base_name_attr()}[child_label]' value='#{val}'/>
      </div>
    ")


  bootstrap_child_label_input = () ->
    if data && data.child_label
      label = data.child_label
    $(child_label_input_template(label)).insertAfter($container)

  # ADDER BUTTON #######

  adder_button_template = () ->
    $("
      <a class='button push--bottom'>#{I18n.t('views.backoffice.customizable_attributes.double_select.add_option')}</a>
    ")

  handle_button = (e) ->
    e.preventDefault()
    insert_double_select_inputs()

  bootstrap_adder_button = () ->
    $button = $(adder_button_template()).insertAfter($container)
    $button.click(handle_button)

  # DATA ######

  load_data = () ->
    try
      data = JSON.parse($elem.val())
    catch error
      data = null
    data

  # MAIN ######

  $elem = $(elem)
  $wrapper = $elem.parent()
  $wrapper.removeClass('input') # prevent messy label autostyling
  $wrapper.prepend('<hr/>')
  $container = $("<div class='push--top'/>").appendTo($wrapper)
  $elem.hide()
  $elem.siblings('label').hide()
  $elem.attr('name', '')
  data = load_data()

  if data
    bootstrap_double_select_inputs_data()
  else
    insert_double_select_inputs()

  bootstrap_child_label_input()
  bootstrap_adder_button()
  $wrapper.append('<hr/>')


# ########################################
# DYNAMIC ATTRIBUTE FORM HELPERS
# ########################################

window.dynamic_attributes_double_select = (elem, opts = {}) ->

  opts = extend({display_label: true}, opts)

  init_double_select = () ->

    construct_full_name_attr = (child = false) ->
      if child
        "#{double_select_name_attr}[child_value][]"
      else
        if opts.search_mode
          "#{double_select_name_attr}[root_value][]"
        else
          "#{double_select_name_attr}[root_value]"

    select_template = (options = [], html_opts = {}) ->
      if html_opts.multiple
        multiple = 'multiple'
      else
        multiple = ''
      $wrapper = $("
        <div class='input flush--bottom #{html_opts.wrapper_html && html_opts.wrapper_html.class}'>
          <select name='#{html_opts.name}' select2title='#{html_opts.select2title || ''}' #{multiple} ></select>
        </div>
        ")
      options.unshift('')
      for option in options
        $option = $("<option />", {
          value: option,
          text: option
        })
        $wrapper.find('select').append($option)
      if html_opts.label && opts.display_label
        $wrapper.prepend("<label class='label--block'>#{html_opts.label}</label>")
      if html_opts.placeholder && opts.display_placeholder
        $wrapper.find('select').attr('data-placeholder', html_opts.placeholder)
      $wrapper

    insert_double_select_inputs = () ->
      $child_wrapper = null
      $child_select = null

      options = keys(double_select_options)
      html_opts = {
        name: construct_full_name_attr()
        label: double_select_label
        select2title: double_select_title
        placeholder: double_select_placeholder
        wrapper_html: opts.parent_wrapper_html
        multiple: opts.search_mode
      }
      $select = select_template(options, html_opts)
        .appendTo($container)
        .find('select')
      $dialog = $select.closest('dialog')
      $select
        .select2({
          dropdownParent: $dialog if $dialog.length
          placeholder: double_select_placeholder || I18n.t('select_an_option'),
          allowClear: true,
          disabled: $elem.attr('disabled'),
        }).change(opts.onChange)
        .change( (e) ->
          if $child_wrapper
            $child_select.select2('destroy')
            $child_wrapper.remove()

          if isArray($select.val())
            root_values = $select.val()
          else
            root_values = [$select.val()]

          options = map(root_values, (value) ->
            double_select_options[value]
          )
          options = flatten(options)
          options = uniq(options)

          html_opts = {
            name: construct_full_name_attr(true)
            label: double_select_config.child_label || $select.val()
            placeholder: $select.val()
            wrapper_html: opts.child_wrapper_html
            multiple: opts.search_mode
          }
          $child_wrapper = select_template(options, html_opts)
            .appendTo($container)
          $dialog = $child_wrapper.find('select').closest('dialog')
          $child_select = $child_wrapper
            .find('select')
            .select2({
              dropdownParent: $dialog if $dialog.length
              placeholder: I18n.t('select_an_option'),
              allowClear: true,
              disabled: $elem.attr('disabled'),
            }).change(opts.onChange)

          if data
            $child_select
              .val(data.child_value)
              .trigger('change')
        ).on('select2:unselecting', (e) ->
          e.preventDefault()
          $(this).val('').trigger('change')
          if $child_wrapper
            $child_wrapper.remove()
            $child_wrapper = null
        )
      if data
        $select
          .val(data.root_value)
          .trigger('change')
        $child_select
          .val(data.child_value)
          .trigger('change')

      $select.next('.select2-container').tooltip({
        title: () ->
          return $(this).prev().attr('select2title');
        placement: 'auto'
      });

    # DATA ######

    load_data = () ->
      try
        data = JSON.parse($elem.val())
      catch error
        data = null
      data

    # MAIN ######

    $elem = $(this)

    unless (double_select_config = $elem.data('double-select-options'))
      throw '[data-double-select-options] attribute not provided'

    if $elem.data('double_select')
      return
    else
      $elem.data('double_select', true)

    $wrapper = $elem.parent()
    $wrapper.removeClass('input') # prevent messy label autostyling
    $container = $("<div class='#{ opts.wrapper_html && opts.wrapper_html.class }'/>").prependTo($wrapper)
    double_select_name_attr = $elem.attr('name')
    double_select_label = $elem.siblings('label').hide().text()
    double_select_placeholder = $elem.attr('placeholder')
    double_select_title = $elem.attr('select2title')

    $elem.hide()
    $elem.attr('name', '')
    double_select_options = double_select_config.options
    data = load_data()

    insert_double_select_inputs()



  $(elem).each () ->
    init_double_select.call(this)

  $(document).on('focus', elem, init_double_select)


window.dynamic_attributes_select = (elem, opts={}) ->

  load_config = ($elem) ->
    try
      $elem.data('select-config')
    catch error
      {}

  init_select = () ->

    $elem = $(this)

    unless ($elem.data('select-config'))
      throw '[data-select-config] attribute not provided'

    config = load_config($elem)

    if !config.remote
      $elem.select2(opts).change(opts.onChange)

    else
      network_id = opts.network_id || config.network_id
      customizable_attribute_id = config.customizable_attribute_id

      $dialog = $elem.closest('dialog')
      $elem.select2(
        dropdownParent: $dialog if $dialog.length
        width: opts.width
        minimumInputLength: 1
        ajax :
          url: Routes.search_customizable_attribute_select_path()
          delay: 250
          dataType: 'JSON'
          data: (term) ->
            extend(term, { network_id: network_id, customizable_attribute_id: customizable_attribute_id })

          processResults: (data) ->
            results = { more: false, results: [] }

            $.each(data, (i, val) ->
              results.results.push(
                id: val
                text: val
              )
            )
            results
      ).change(opts.onChange)
    $elem.next('.select2-container').tooltip({
      title: () ->
        return $(this).prev().attr('select2title');
      placement: 'auto'
    });


  $(elem).each () ->
    init_select.call(this);



window.dynamic_attribute_value_present = (dynamic_attribute) ->
  attr_value = dynamic_attribute.attr_value

  if attr_value == false # boolean `false` value is present; migrating to ES6 be sure to check with "==="
    true
  else if isArray(attr_value)  # type 'select' value present ?
    attr_value.length > 0
  else if isObject(attr_value) # type 'double_select' value present ?
    attr_value.child_value && attr_value.child_value[0].length > 0
  else
    attr_value

## Adjusted copy of `dynamic_attributes_double_select` with implementation of react_select instead of select2.
window.dynamic_attributes_double_react_select = (elem, opts = {}) ->

  opts = extend({display_label: true}, opts)

  init_double_select = () ->

    construct_full_name_attr = (child = false) ->
      if child
        "#{double_select_name_attr}[child_value][]"
      else
        if opts.search_mode
          "#{double_select_name_attr}[root_value][]"
        else
          "#{double_select_name_attr}[root_value]"

    select_template = (html_opts = {}) ->
      if html_opts.multiple
        multiple = 'multiple'
      else
        multiple = ''
      $wrapper = $("
        <div class='input #{html_opts.wrapper_html && html_opts.wrapper_html.class || ''}'>
          <div class='react-select-wrap'></div>
        </div>
        ")
      if html_opts.label && opts.display_label
        $wrapper.prepend("<label class='label--block'>#{html_opts.label}</label>")
      $wrapper

    insert_double_select_inputs = () ->
      $child_wrapper = null
      $child_select = null

      on_child_change = (selected) ->
        if typeof opts.onChange == 'function'
          opts.onChange()

      on_root_change = (selected, child_value) ->
        if $child_wrapper
          ReactDOM.unmountComponentAtNode($child_wrapper[0]);
          $child_wrapper.remove()

        unless selected
          return

        if isArray(selected)
          root_values = map(selected, (s) ->
            if isObject(s) then s.value else s
          )
        else
          root_values = [if isObject(selected) then selected.value else selected]

        options = map(root_values, (value) ->
          double_select_options[value]
        )
        options = flatten(options)
        options = uniq(options)

        html_opts = {
          name: construct_full_name_attr(true)
          label: double_select_config.child_label || root_values[0]
          placeholder: root_values[0]
          wrapper_html: opts.child_wrapper_html
          multiple: opts.search_mode
        }

        $child_wrapper = select_template(html_opts)
          .appendTo($container)

        defaultValue = if isArray(child_value) && html_opts.multiple
          child_value
        else if isArray(child_value)
          child_value[0]
        else
          child_value

        ReactSelectChildComponents = React.createElement(ReactSelect, {
          name: html_opts.name
          ariaLabel: html_opts.label
          options: options
          defaultValue: defaultValue
          disabled: $elem.attr('disabled')
          placeholder: I18n.t('select_an_option')
          multiple: html_opts.multiple
          isClearable: true
          onChange: on_child_change
        })
        ReactDOM.render(ReactSelectChildComponents, $child_wrapper.find('.react-select-wrap')[0])

      options = keys(double_select_options)
      html_opts = {
        name: construct_full_name_attr()
        label: double_select_label
        select2title: double_select_title
        placeholder: double_select_placeholder
        wrapper_html: opts.parent_wrapper_html
        multiple: opts.search_mode
      }
      $react_container = select_template(html_opts)
        .appendTo($container)
        .find('.react-select-wrap')

      ReactSelectComponents = React.createElement(ReactSelect, {
        name: html_opts.name
        ariaLabel: double_select_label
        options: options
        defaultValue: data && data.root_value
        disabled: $elem.attr('disabled')
        # required: select.required
        placeholder: double_select_placeholder || I18n.t('select_an_option')
        multiple: html_opts.multiple
        isClearable: true
        onChange: on_root_change
      });

      ReactDOM.render(ReactSelectComponents, $react_container[0]);

      if data
        on_root_change(data.root_value, data.child_value);
        on_child_change()

      $react_container.tooltip({
        title: () ->
          double_select_title
        placement: 'auto'
      })

    # DATA ######

    load_data = () ->
      try
        data = JSON.parse($elem.val())
      catch error
        data = null
      data

    # MAIN ######

    $elem = $(this)

    unless (double_select_config = $elem.data('double-select-options'))
      throw '[data-double-select-options] attribute not provided'

    if $elem.data('double_select')
      return
    else
      $elem.data('double_select', true)

    $wrapper = $elem.parent()
    $wrapper.removeClass('input') # prevent messy label autostyling
    $container = $("<div class='#{ opts.wrapper_html && opts.wrapper_html.class || '' }'/>").prependTo($wrapper)
    double_select_name_attr = $elem.attr('name')
    double_select_label = $elem.siblings('label').hide().text()
    double_select_placeholder = $elem.attr('placeholder')
    double_select_title = $elem.attr('select2title')

    $elem.hide()
    $elem.attr('name', '')
    double_select_options = double_select_config.options
    data = load_data()

    insert_double_select_inputs()



  $(elem).each () ->
    init_double_select.call(this)

  $(document).on('focus', elem, init_double_select)
