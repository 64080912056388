$ = jQuery

$.fn.extend
  initJobForm: (options) ->
    # Default settings
    settings =
      bizopsKindsCollection: null

    settings = $.extend settings, options

    bizops_placeholder = (kind_name) ->
      switch kind_name
        when 'business_opportunities.kind.full_time_job'
          title               : I18n.t('web.business_opportunities.form.job_title')
          location            : I18n.t('web.business_opportunities.form.job_location')
          description         : I18n.t('web.business_opportunities.form.job_description')
          industry_placeholder: 'Please select industries related to the job'
        when 'business_opportunities.kind.find_job'
          title               : I18n.t('web.business_opportunities.form.job_title')
          location            : I18n.t('web.business_opportunities.form.job_location')
          description         : I18n.t('web.business_opportunities.form.job_description')
          industry_placeholder: 'Please select industries related to the job'
        when 'business_opportunities.kind.short_term_project'
          title               : I18n.t('web.business_opportunities.form.project_title')
          location            : I18n.t('web.business_opportunities.form.project_location')
          description         : I18n.t('web.business_opportunities.form.project_description')
          industry_placeholder: 'Please select industries related to the project'
        when 'business_opportunities.kind.find_a_team_member'
          title               : I18n.t('web.business_opportunities.form.job_title')
          location            : I18n.t('web.business_opportunities.form.job_location')
          description         : I18n.t('web.business_opportunities.form.job_description')
          industry_placeholder: 'Please select industries related to the job'
        when 'business_opportunities.kind.find_mentorship'
          title               : I18n.t('web.business_opportunities.form.menthorship_title')
          location            : I18n.t('web.business_opportunities.form.menthorship_location')
          description         : I18n.t('web.business_opportunities.form.menthorship_description')
          industry_placeholder: 'Please select industries related to your request'
        when 'business_opportunities.kind.find_contacts'
          title               : I18n.t('web.business_opportunities.form.contacts_title')
          location            : I18n.t('web.business_opportunities.form.contacts_location')
          description         : I18n.t('web.business_opportunities.form.contacts_description')
          industry_placeholder: 'Please select industries related to your request'
        when 'business_opportunities.kind.offer_internship'
          title               : I18n.t('web.business_opportunities.form.internship_title')
          location            : I18n.t('web.business_opportunities.form.internship_location')
          description         : I18n.t('web.business_opportunities.form.internship_description')
          industry_placeholder: 'Please select industries related to your request'
        when 'business_opportunities.kind.find_internship'
          title               : I18n.t('web.business_opportunities.form.internship_title')
          location            : I18n.t('web.business_opportunities.form.internship_location')
          description         : I18n.t('web.business_opportunities.form.internship_description')
          industry_placeholder: 'Please select industries related to your request'
        when 'business_opportunities.kind.call'
          title               : I18n.t('web.business_opportunities.form.call_title')
          location            : I18n.t('web.business_opportunities.form.call_location')
          description         : I18n.t('web.business_opportunities.form.call_description')
          industry_placeholder: 'Please select industries related to your request'
        when 'business_opportunities.kind.voluntary_opportunity'
          title               : I18n.t('web.business_opportunities.form.voluntary_opportunity_title')
          location            : I18n.t('web.business_opportunities.form.voluntary_opportunity_location')
          description         : I18n.t('web.business_opportunities.form.voluntary_opportunity_description')
          industry_placeholder: 'Please select industries related to your request'
        when 'business_opportunities.kind.other'
          title               : I18n.t('web.business_opportunities.form.other_title')
          location            : I18n.t('web.business_opportunities.form.other_location')
          description         : I18n.t('web.business_opportunities.form.other_description')
          industry_placeholder: 'Please select industries related to your request'

    updateForms = (e) ->
      bizopsKindId = parseInt($(@).find('.js-bizops-type-select').val())
      bizopsKindId = settings.bizopsKindsCollection.first().id unless bizopsKindId
      if (bizopsKindId)
        $(@).find('.js-bizopps-optional').hide()
        BizopskindModelUsed = settings.bizopsKindsCollection.get(bizopsKindId)

        switch BizopskindModelUsed.get('name')
          when 'business_opportunities.kind.full_time_job'
            $(@).find('
              .js-bizopps-posting-job,
              .js-bizops-url-posting-job,
              .js-bizops-contract-types-select,
              .js-bizops-experience-types-select,
              .js-bizopps-full-time-job,
              .js-bizops-url,
              .js-start-date,
              .show1
            ').show()
          when 'business_opportunities.kind.voluntary_opportunity'
            $(@).find('.js-bizops-industries').hide()
            $(@).find('
              .js-bizopps-posting-job,
              .js-bizops-url-posting-job,
              .js-bizops-url,
              .js-start-date,
              .show1
            ').show()
          when 'business_opportunities.kind.find_job'
            $(@).find('
              .js-bizopps-full-time-job,
              .js-bizops-contract-types-select,
              .js-bizops-experience-types-select,
              .js-start-date,
              .js-bizopps-posting-job,
              .show1
            ').show()
          when 'business_opportunities.kind.short_term_project'
            $(@).find('
              .js-bizopps-posting-job,
              .js-bizops-url-posting-job,
              .js-bizops-contract-types-select,
              .js-bizops-experience-types-select,
              .js-bizopps-short-term,
              .js-bizops-url,
              .js-start-date,
              .show2
            ').show()
          when 'business_opportunities.kind.find_a_team_member'
            if ($('#has-no-ventures').length > 0)
              $(@).find('#biz-op-form').hide()
              $(@).find('#has-no-ventures').show()
            else
              $(@).find('.js-bizopps-team-member').show()
            $(@).find('
              .js-bizops-url,
              .js-bizopps-posting-job,
              .js-bizops-url-posting-job,
              .js-start-date
            ').show()
          when 'business_opportunities.kind.find_contacts'
            $(@).find('
              .js-bizops-url,
              .js-bizopps-posting-job,
              .js-bizops-url-posting-job
            ').show()
          when 'business_opportunities.kind.offer_internship'
            $(@).find('
              .js-bizopps-posting-job,
              .js-bizops-url-posting-job,
              .js-start-date,
              .js-bizops-url,
              .js-bizopps-internship
            ').show()
          when 'business_opportunities.kind.find_internship'
            $(@).find('
              .js-bizopps-internship,
              .js-start-date,
              .js-bizopps-posting-job
            ').show()
          when 'business_opportunities.kind.call'
            $(@).find('.js-location-request').hide()
            $(@).find('
              .js-bizopps-posting-job,
              .js-bizops-url,
              .js-bizopps-posting-job
            ').show()
          when 'business_opportunities.kind.other'
            $(@).find('
              .js-bizops-url,
              .js-start-date
            ').show()
          when 'business_opportunities.kind.find_mentorship'
            $(@).find('.js-bizopps-posting-job').show()
          else
            null
        updatePlaceholders(BizopskindModelUsed)
      else
        console.warn 'An error was occured!'

    updatePlaceholders = (BizopskindModelUsed) ->
      kind_name = BizopskindModelUsed.get('name')

      $(@).find('.js-bizops-title').attr('placeholder', bizops_placeholder(kind_name).title)
      $(@).find('.js-bizops-location').attr('placeholder', bizops_placeholder(kind_name).location)
      $(@).find('.search-field input').val(bizops_placeholder(kind_name).industry_placeholder)

    return @each () ->
      $(@).find(".js-bizops-type-select").on 'change', $.proxy(updateForms, @)
      $(@).on 'remove', () => @el.find(".js-bizops-type-select").unbind('change')
      $.proxy(updateForms, @)()
