import { LDProvider } from 'launchdarkly-react-client-sdk';
import { kebabCase } from 'lodash';
import { useQuery } from 'react-query';

import { axiosInstance as axios } from 'hb-react/shared/utils/axios';

const DEFAULT_FLAG_VALUE = false;
export const ALL_FLAGS_STORE = {
  items: null,
};

const handleCatch = (error) => {
  // If we get a 404 it's likely because we are in the super admin
  // backoffice where no network is selected and the feature_flags_controller
  // is not available which is expected. Any other error should be treated as such.
  if (error.response?.status !== 404) {
    throw error;
  }
};

const getFeatureFlagConfig = () =>
  axios
    .get(Routes.feature_flag_settings_path())
    .then(({ data }) => data)
    .catch(handleCatch);

const getLocaleFlags = () =>
  window.__HB_ENV__.LAUNCHDARKLY_CLIENT_SIDE_ID ||
  process.env.NODE_ENV === 'test'
    ? null // JEST & PROD path
    : axios // DEV & CUCUMBER path
        .get(Routes.feature_flag_all_flags_path())
        .then(({ data }) => data)
        .then((allFlags) => (ALL_FLAGS_STORE.items = allFlags))
        .catch(handleCatch);

const valueOrDefault = (value, defaultValue) => {
  if (value === true || value === false) return value;

  return defaultValue;
};

export const useLaunchDarklyProviderConfig = () => {
  const { status, data } = useQuery(
    'launchDarkly',
    getLaunchDarklyProviderConfig,
  );

  return { status, providerConfig: data };
};

export const getLaunchDarklyProviderConfig = async () => {
  const featureFlagConfig = await getFeatureFlagConfig();

  await getLocaleFlags();

  return window.__HB_ENV__.LAUNCHDARKLY_CLIENT_SIDE_ID
    ? {
        provider: LDProvider,
        clientSideID: window.__HB_ENV__.LAUNCHDARKLY_CLIENT_SIDE_ID,
        context: {
          kind: 'user',
          ...(featureFlagConfig || {}),
          ...(featureFlagConfig?.custom || {}),
        },
      }
    : null;
};

export const getFlag = (flag) => {
  const flagName = Object.keys(flag)[0];
  const flagValue = flag[flagName];
  const key = kebabCase(flagName); // my-feature-v-2-foo,
  const compactKey1 = key.replace(/([\w])-([\d])/, '$1$2'); // my-feature-v2-foo
  const compactKey2 = compactKey1.replace(/([\d])-([\w])/, '$1$2'); // my-feature-v2foo

  // DEV or CUCUMBER path
  if (ALL_FLAGS_STORE.items !== null) {
    return valueOrDefault(
      ALL_FLAGS_STORE.items[key] ||
        ALL_FLAGS_STORE.items[compactKey1] ||
        ALL_FLAGS_STORE.items[compactKey2],
      DEFAULT_FLAG_VALUE,
    );
  }

  // PROD path
  if (window.__HB_ENV__.LAUNCHDARKLY_CLIENT_SIDE_ID) {
    return flagValue;
  }

  // JEST and default path
  return valueOrDefault(flagValue, DEFAULT_FLAG_VALUE);
};
