$(document).ready(function() {
  $.fancybox.defaults.padding            = 0;

  $.fancybox.defaults.overlayOpacity     = 0.45;
  $.fancybox.defaults.overlayColor       = '#000';
  $.fancybox.defaults.scrolling          = 'no';
  $.fancybox.defaults.autoScale          = false;
  $.fancybox.defaults.autoSize           = true;
  $.fancybox.defaults.minWidth           = 400;
  $.fancybox.defaults.enableEscapeButton = false;
  if (typeof Backbone !== 'undefined') {
    $.fancybox.defaults.onClosed = function() {
      Backbone.history.navigate("home")
    }
  }

  var fancy       = {};
  fancy.oldHeight = 0;
  fancy.$el       = $('#fancybox-wrap');
  fancy.$el.unbind('mousewheel'); //Hack to restore fancybox scroll (confllict with jquery.datetimepicker)

});
