/* eslint-disable react/jsx-filename-extension */

import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import DefaultAppContexts from 'hb-react/frontoffice/contexts/DefaultAppContexts';
import Loader from 'hb-react/shared/components/Loader';
import * as errorReporting from 'hb-react/shared/utils/errorReporting';
import ThemeProvider from 'hb-react/style/ThemeProvider';

import * as boComponents from './components/backoffice';
import * as poComponents from './components/publicoffice';
import * as sharedComponents from './components/shared';
import { initializeFrontendSdk } from '../frontendSdk';

initializeFrontendSdk();

const computeComponents = (prefix, components) =>
  Object.keys(components).reduce((result, key) => {
    result[`${prefix}.${key}`] = components[key];

    return result;
  }, {});

const components = {
  ...computeComponents('bo', boComponents),
  ...computeComponents('po', poComponents),
  ...computeComponents('shared', sharedComponents),
};

const renderComponent = (node) => {
  const {
    dataset: {
      component: componentName,
      loader,
      props,
      theme: componentTheme,
      customNetworkColors,
      currentBackofficeAdmin,
      currentNetworkId,
    },
  } = node;

  // Don't keep huge JSON in DOM after we use it
  node.removeAttribute('data-component');
  node.removeAttribute('data-loader');
  node.removeAttribute('data-props');
  node.removeAttribute('data-custom-network-colors');
  node.removeAttribute('data-current-backoffice-admin');
  node.removeAttribute('data-current-network-id');

  const component = components[componentName];

  if (!component) {
    errorReporting.captureMessage(
      `Component "${componentName}" is not defined`,
    );

    return;
  }

  const { component: Component, theme: defaultTheme } = component;

  let parsedProps = {};
  let parsedLoader = false;
  let parsedCustomNetworkColors = {};
  let parsedCurrentBackofficeAdmin;
  let parsedCurrentNetworkId;

  try {
    parsedProps = props && JSON.parse(props);
    parsedLoader = loader && JSON.parse(loader);
    parsedCustomNetworkColors =
      customNetworkColors && JSON.parse(customNetworkColors);
    parsedCurrentBackofficeAdmin =
      currentBackofficeAdmin && JSON.parse(currentBackofficeAdmin);
    parsedCurrentNetworkId =
      currentNetworkId && Number(JSON.parse(currentNetworkId));
  } catch {} // eslint-disable-line no-empty

  const currentTheme = componentTheme || defaultTheme;

  const componentRender = (
    <Suspense fallback={parsedLoader && <Loader {...parsedLoader} />}>
      <DefaultAppContexts.Provider
        currentAdmin={parsedCurrentBackofficeAdmin}
        currentNetworkId={parsedCurrentNetworkId}
      >
        <Component {...parsedProps} />
      </DefaultAppContexts.Provider>
    </Suspense>
  );

  createRoot(node).render(
    currentTheme ? (
      <ThemeProvider
        theme={currentTheme}
        options={{ customNetworkColors: parsedCustomNetworkColors }}
      >
        {componentRender}
      </ThemeProvider>
    ) : (
      componentRender
    ),
  );
};

window.__RENDER_COMPONENT__ = renderComponent;

document.addEventListener('DOMContentLoaded', () => {
  const nodes = Array.from(document.querySelectorAll('[data-component]'));

  nodes.forEach(renderComponent);
});
