module.exports =(function() {
  return function(context) {
    return (function() {
      var $c, $e, $o, htmlSafe;
      $e = function(text, escape) {
        return ("" + text).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&#39;').replace(/\//g, '&#47;').replace(/"/g, '&quot;');
      };
      $c = function(text) {
        switch (text) {
          case null:
          case void 0:
            return '';
          case true:
          case false:
            return '' + text;
          default:
            return text;
        }
      };
      $o = [];
      htmlSafe = require('helpers/htmlSafe').htmlSafe;
      $o.push("<div class='muted-link rounded search-item soft-half'>\n  <div class='flexbox'>\n    <div class='flexbox__item v-middle'>\n      <img class='block' src='" + ($e($c(this.photo_url))) + "' height='" + ($e($c(42))) + "' width='" + ($e($c(42))) + "'>\n    </div>\n    <div class='flexbox__item soft-half--left ten-twelfths v-middle'>\n      <div class='weight--bold'>" + ($c(this.full_name)) + "</div>");
      if (this.headline) {
        $o.push("      <div class='color-gray'>" + ($c(htmlSafe(AlumniConnect.Helpers.Global.truncate(this.headline, 80)))) + "</div>");
      }
      $o.push("    </div>\n  </div>\n</div>");
      return $o.join("\n").replace(/\s([\w-]+)='true'/mg, ' $1').replace(/\s([\w-]+)='false'/mg, '').replace(/\s(?:id|class)=(['"])(\1)/mg, "").replace(/[\s\n]*\u0091/mg, '').replace(/\u0092[\s\n]*/mg, '');
    }).call(context);
  };

}).call(this);
