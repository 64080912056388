import Loader from 'hb-react/shared/components/Loader'
import { mapImage } from 'helpers/mapImage'
import * as mobileDeviceFromUserAgent from 'helpers/mobileDeviceFromUserAgent'

window.AlumniConnect.Helpers.Global =
  ##
  # Get precise parameter from url
  # @param  {[string]} url [url]
  # @return {[Object]}     [url parameters]
  ##
  getUrlParameter: (url) ->
    regex = /[?&]([^=#]+)=([^&#]*)/g
    params = {}
    while (match = regex.exec(url))
      params[match[1]] = decodeURIComponent(match[2])
    params

  resetForm: (selector) ->
    selector.find('input:text, input:password, input:file, select, textarea').val('')
    selector.find('input:radio, input:checkbox').removeAttr('checked').removeAttr('selected')

  ##
  # Truncate a string to the given length, breaking at word boundaries and adding an elipsis
  # @param string str String to be truncated
  # @param integer limit Max length of the string
  # @return string
  ##
  truncate: (str, limit = 100) ->
    return '' if (typeof str != 'string' || str == '')

    bits = str.split('')

    if bits.length > limit
      i = bits.length - 1
      while i > -1
        if i > limit
          bits.length = i
        else if ' ' == bits[i]
          bits.length = i
          break
        --i
      bits.push '...'
    bits.join ''

  simple_format: (str) ->
    breakTag = '<br />'
    String(str).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')

  isMobile: mobileDeviceFromUserAgent

  boNotification: (message, succes=true) ->
    $notif = $("""
     <div class="bo-notification #{if succes then 'success' else 'error'}">
       #{message}
       <a class="close" role="button" data-behavior="close-notif">
         <i class="icon-multiply"></i>
       </a>
     </div>
    """)
    $(document.body).append($notif);
    setTimeout ->
      $notif.fadeTo(500,0).slideUp();
    , 5000

  initRemoteForm: (namespace="") ->
    that = this

    $("#{namespace} [data-behavior=auto-submit-form] input, #{namespace} [data-behavior=auto-submit-form] textarea, #{namespace} [data-behavior=auto-submit-form] select").change ->
      $form = $(this).closest('form')
      $('body').prepend('<div class="js-global-loader"/>')
      ReactDOM.render(React.createElement(Loader, { full: true }), $('.js-global-loader')[0])
      $form.submit()

    $(document).on 'change', "#{namespace} [data-behavior=remote-form] input, #{namespace} [data-behavior=remote-form] textarea, #{namespace} [data-behavior=remote-form] select", (e) ->
      that.remoteSave(e)

  remoteSave: (e) ->
    $input = $(e.target)
    notifBottom = $input.data('notif') == 'bottom';

    unless $input.data('remote-form') == 'ignore'
      $form = $($input).closest('form')
      $form.submit()
      savingClass = 'label-remote label-remote--saving'
      if notifBottom
        savingClass+= ' display-bottom'
      $notif = $('<span>').addClass(savingClass).text(I18n.t('actions.saving'))
      $($input).before($notif)

      $form
        .on "ajax:success", ->
          $notif.removeClass('label-remote--saving')
          $notif.text(I18n.t('notices.successfully_saved'))
          $notif.addClass('label-remote--success')
          setTimeout ->
            $notif.fadeOut('slow', () => $notif.remove())
          , 2000
        .on "ajax:error", (event, jqxhr) =>
          errorText = @composeErrorText($input, jqxhr)
          $notif.removeClass('label-remote--saving')
          $notif.text("#{I18n.t('notices.error_occured')}. #{errorText}")
          $notif.addClass('label-remote--error')
          setTimeout ->
            $notif.fadeOut('slow', () => $notif.remove())
          , 6000

  composeErrorText: ($elem, jqxhr) ->
    try
      json = JSON.parse(jqxhr.responseText)
    catch e
      json = null

    str = ''
    if !json || !json.errors || !json.errors_full_messages
      return str

    if json.errors_full_messages
      str += json.errors_full_messages.join(', ')
    else if json.errors instanceof Array
      str += json.errors.join(', ')
    else
      for attr, errors of json.errors
        label = @findLabelFromAttributeName(attr, $elem)
        str += " #{label}: #{errors.join(', ')}."
    str

  findLabelFromAttributeName: (attr, $elem) ->
    $input = $elem.find("[id][name*=#{attr}]")
    $label = $input.parents('form').find("label[for=#{$input.attr('id')}]")
    if $elem[0] == document.body
      attr.replace(/_/g, ' ')
    else if $input.length > 0 && $label.length > 0
      $input.parents('form').find("label[for=#{$input.attr('id')}]").text()
    else
      @findLabelFromAttributeName(attr, $elem.parent())


  #--------------------------- Input label stuff
  initInputs: (namespace="") ->
    input_selector = "#{namespace} .input input:not([type=hidden]), #{namespace} .input textarea"

    updateInputClass = ->
      if @value and @value.length > 0 or @checked
        $(this).closest('.input').addClass 'input--filled'
      else
        $(this).closest('.input').removeClass 'input--filled'


    $('select:not([data-type=browser-default])', namespace).select2()
    $(document).on 'click', "#{namespace} .input.select label", ->
      $select = $(this).siblings('select')
      $select.select2 'open'

    $(document).on 'change', "#{namespace} select:not([data-type=browser-default])", ->
      $input_element      = $(this)
      $placeholder_option = $input_element.find('option[value=""]')
      # If there is a value OR if there is a placeholder
      if $input_element.val() || ($placeholder_option.length > 0 && $placeholder_option.text().length > 0)
        $input_element.closest('.input').addClass 'input--filled'
      else
        $input_element.closest('.input').removeClass 'input--filled'

    $(document).on 'change', input_selector, updateInputClass
    $(document).on 'typeahead:selected', input_selector, updateInputClass
    # Add active on focus
    $(document).on 'focus', input_selector, ->
      $(this).closest('.input').addClass 'input--active'

    # Remove active on blur if val empty
    $(document).on 'blur', input_selector, ->
      $(this).closest('.input').removeClass 'input--active'

    $(input_selector).each ->
      updateInputClass.call(this)
    $('select:not([data-type=browser-default])', namespace).change()

  focusOnFirstError: (firstParent, secondParent, findElem) ->
    errorElement = null
    errorGroup = $('span.error').first().parents(firstParent).first().find(findElem)

    if errorGroup.length == 0
      errorGroup = $('span.error').first().parents(secondParent).first().find(findElem)

    if errorGroup.length
      errorElement = errorGroup.first()

    if errorElement
      errorElement.focus()

  fixSpanIds: (firstParent, secondParent, findElem) ->
    $('span.error').each ->
      inputsSelector = $(this).parents(firstParent).first().find(findElem)

      if inputsSelector.length == 0
        inputsSelector = $(this).parents(secondParent).first().find(findElem)

      if inputsSelector.length
        describedby = inputsSelector.first().attr('aria-describedby')

        if describedby
          $(this).attr('id', describedby)
