import * as Sentry from '@sentry/react';
import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import ignoreErrors from 'hb-react/shared/utils/errorReporting/ignoreErrors';
import ignoreUrls from 'hb-react/shared/utils/errorReporting/ignoreUrls';
import {
  setBreadcrumbLegacyJSResponse,
  setEventLegacyJSResponse,
} from 'hb-react/shared/utils/errorReporting/legacyJSResponse';
import setOfficeTag from 'hb-react/shared/utils/errorReporting/setOfficeTag';

let isInit = false;

export const initSentry = (envs) => {
  if (envs.SENTRY_DSN_JAVASCRIPT && envs.CLUSTER_NAME) {
    Sentry.init({
      dsn: envs.SENTRY_DSN_JAVASCRIPT,
      environment: envs.CLUSTER_NAME,
      release: envs.APP_VERSION,

      // Add stacktrace on all message
      attachStacktrace: true,
      integrations: [
        Sentry.browserTracingIntegration({
          enableInp: true,
          routingInstrumentation: Sentry.reactRouterV6BrowserTracingIntegration(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      tracesSampleRate: 1.0,
      ignoreErrors,
      ignoreUrls,

      beforeBreadcrumb(breadcrumb, hint) {
        setBreadcrumbLegacyJSResponse(breadcrumb, hint);

        return breadcrumb;
      },
      beforeSend: (event) => {
        // Override default filename origin
        event?.stacktrace?.frames?.forEach((frame) => {
          const { pathname } = new URL(frame.filename);

          frame.filename = `~${pathname}`;
        });

        setEventLegacyJSResponse(event);
        setOfficeTag(event);

        return event;
      },
    });

    isInit = true;
  }
};

export const setContext = ({ user, network }) => {
  if (user) {
    Sentry.setUser(user);
  }

  if (network) {
    Sentry.setExtras({ network });
    Sentry.setTag('network', network.id);
    Sentry.setTag('network.identifier', network.identifier);
  }
};

const SentryLogger = {
  captureMessage: (message) => isInit && Sentry.captureMessage(message),
  captureException: (err, context) =>
    isInit && Sentry.captureException(err, context),
};

const DevLogger = {
  // eslint-disable-next-line no-console
  captureMessage: (message) => console.trace(message),
  // eslint-disable-next-line no-console
  captureException: (err, context) => console.trace(err, context),
};

export const isDevEnvironment = process.env.NODE_ENV === 'development';
export const isTestEnvironment = process.env.NODE_ENV === 'test';

const Logger = isInit ? SentryLogger : DevLogger;

export const captureMessage = (message) => Logger.captureMessage(message);

export const captureException = (err, context) =>
  Logger.captureException(err, context);
